<template>
    <section class="companies" ref="container">
        <div class="container">
            <div class="title">AMO is international IT company with three independent departments, united by culture and vibe:</div>
            <div class="company" v-for="item in companies" v-bind:key="item.id">
                <div class="wrapper">
                    <div class="name" v-html="name(item)"></div>
                    <div class="description" v-html="item.description"></div>
                    <a href="" class="action" :style="{ color: item.color }">
                        <span>{{ explore(item.name) }}</span>
                        <svg>
                            <use xlink:href="#icon-arrow" />
                        </svg>
                    </a>
                </div>
                <svg class="image">
                    <defs>
                        <mask :id="'company-mask' + item.id">
                            <polygon points="0 0, 0 0, 0 0" fill="#FFFFFF" />
                        </mask>
                    </defs>
                    <!-- <foreignObject x="0" y="0" width="500" height="400" :mask="'url(#company-mask' + item.id + ')'">
                        <video xmlns="http://www.w3.org/1999/xhtml" width="300" height="200" controls="" muted autoplay>
                            <source src="http://techslides.com/demos/sample-videos/small.mp4" type="video/mp4" />
                        </video>
                    </foreignObject> -->
                    <image preserveAspectRatio="xMinYMin slice" xlink:href="@/assets/images/image01.jpg" :mask="'url(#company-mask' + item.id + ')'" />
                </svg>
                <div class="background"></div>
            </div>
        </div>
    </section>
</template>
<script>
import { ref, onMounted } from 'vue';
import { gsap } from 'gsap';

export default {
    name: 'amo-companies',
    setup() {
        const companies = ref([
            {
                id: 1,
                name: 'AMO PUBLISHING',
                description: 'The international media publisher with more than 35 millions users per month.',
                color: '#f9a04d',
                mask: {
                    from: '0 90, 100 70, 100 70',
                    to: '0 90, 100 60, 100 105'
                }
            },
            {
                id: 2,
                name: 'AMO PICTURES',
                description: 'The digital-first video publisher with more than 800 million views per month. ',
                color: '#a3b1e6',
                mask: {
                    from: '0 100, 0 100, 100 50',
                    to: '0 80, 0 130, 100 50'
                }
            },
            {
                id: 3,
                name: 'AMO APP',
                description: 'The mobile app developer that help us all become better and healthier.',
                color: '#7ae1bf',
                mask: {
                    from: '0 40, 100 80, 100 80',
                    to: '0 40, 100 105, 100 60'
                }
            }
        ]);

        const container = ref(null);

        let companyElement = {
            width: 0,
            height: 0
        };

        onMounted(() => {
            const resize = () => {
                const rect = container.value.querySelector('.company').getBoundingClientRect();

                companyElement.width = rect.width;
                companyElement.height = rect.height;
            };

            window.addEventListener('resize', resize);

            resize();
            observe();
        });

        const recalculate = (data) => {
            let converted = [];

            data.split(', ').forEach((chunk) => {
                const c = chunk.split(' ');

                converted.push((c[1] / 100) * companyElement.width + ' ' + (c[0] / 100) * companyElement.height);
            });

            return converted.join(', ');
        };

        const explore = (name) => {
            return 'EXPLORE ' + name;
        };

        const name = (item) => {
            return '<p>' + item.name.replace(' ', '</p><p style="color: ' + item.color + '">') + '</p>';
        };

        const whichChild = (elem) => {
            let i = 0;
            while ((elem = elem.previousSibling) != null) ++i;
            return i;
        };

        const observe = () => {
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        const intersecting = entry.isIntersecting;
                        const index = whichChild(entry.target) - 2;

                        if (companies.value[index].mask) {
                            gsap.to(entry.target.querySelector('polygon'), {
                                duration: 1,
                                attr: {
                                    points: intersecting ? recalculate(companies.value[index].mask.to) : recalculate(companies.value[index].mask.from)
                                }
                            });
                        }
                    });
                },
                {
                    threshold: 0.5
                }
            );

            container.value.querySelectorAll('.company').forEach((company, index) => {
                if (companies.value[index].mask) {
                    observer.observe(company);
                    company.querySelector('polygon').setAttribute('points', recalculate(companies.value[index].mask.from));
                }
            });
        };

        return {
            companies,
            explore,
            name,
            container
        };
    }
};
</script>
<style lang="scss">
.companies {
    .title {
        color: #fff2d8;
        font-size: size(50);
        letter-spacing: -0.05em;
        line-height: 100%;
        max-width: size(840);
        margin: size(100) 0;
        font-weight: bold;
    }

    .background {
        position: absolute;
        inset: 0;
        transition: transform $fast;
        z-index: 1;
        border-radius: size(28);
        background-color: #222222;
    }

    .image {
        position: absolute;
        inset: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        transition: transform $fast;
        border-radius: size(28);

        image {
            width: 100%;
            height: 100%;
        }
    }

    .company {
        margin-bottom: size(160);
        position: relative;
        cursor: pointer;

        &:hover {
            .background {
                transform: scale(1.03);
            }

            .name {
                transform: translate(-1rem, -1rem);
            }

            .action {
                transform: translate(-1rem, 1rem);

                svg {
                    transform: translate(size(10), size(3));
                }
            }

            .description {
                transform: translate(-1rem, 0.5rem);
            }

            .image {
                transform: scale(1.03);
            }
        }
    }

    .name {
        font-size: size(100);
        line-height: 90%;
        letter-spacing: -0.05em;
        color: #fff2d8;
        font-weight: bold;
        transition: transform $fast;
    }

    .action {
        text-transform: uppercase;
        font-size: size(20);
        font-weight: 500;
        display: inline-flex;
        align-items: center;
        color: #fff2d8;
        transition: transform $fast;

        &:hover {
            svg {
                transform: translate(size(10), size(3));
            }
        }

        svg {
            margin-left: size(4);
            width: size(22);
            height: size(11);
            transform: translate(0, size(3));
            transition: transform $fast;
        }
    }

    .description {
        max-width: size(400);
        font-size: size(20);
        line-height: 100%;
        margin: size(45) 0;
        transition: transform $fast;
        color: #fff2d8;
    }

    .wrapper {
        padding: size(80) 0 size(80) size(80);
        position: relative;
        z-index: 2;
    }
}
</style>