<template>
    <nav>
        <div class="container">
            <div class="columns is-gapless">
                <div class="column is-narrow">
                    <a href="" class="logo is-active">AMO</a>
                    <a href="">ABOUT</a>
                </div>
                <div class="column is-narrow">
                    <a href="">PUBLISHING</a>
                    <a href="">PICTURES</a>
                    <a href="">APP</a>
                </div>
                <div class="column is-narrow">
                    <a href="">LIFE</a>
                    <a href="">PRESS</a>
                    <a href="">CAREER</a>
                </div>
                <div class="column narrow">
                    <a href="">CONTACT</a>
                </div>
                <div class="column has-text-right">
                    <a href="" class="is-active">EN</a>
                    <a href="">UA</a>
                </div>
            </div>
        </div>
    </nav>
</template>
<script>
export default {
    name: 'amoNavigation'
};
</script>
<style lang="scss">
nav {
    position: fixed;
    width: 100%;
    top: 0;
    display: flex;
    height: size(50);
    align-items: center;
    justify-content: center;
    color: $beige;
    background-color: #000;
    z-index: 1000;

    a {
        font-size: size(13);
        font-weight: bold;
        text-transform: uppercase;

        color: inherit !important;
        opacity: 0.3;
        transition: opacity $fast;

        &.is-active {
            opacity: 1;
        }

        &:hover {
            opacity: 1;
        }

        &:first-child {
            margin-left: size(80);
        }

        &.logo {
            margin-left: 0;
        }
    }

    a + a {
        margin-left: size(12);
    }
}
</style>