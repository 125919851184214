<template>
    <section class="hero">
        <div class="container">
            <svg ref="svg">
                <defs>
                    <mask id="hero-mask">
                        <polygon points="20 0, 0 0, 0 0" fill="#FFFFFF" ref="mask" />
                    </mask>
                </defs>
                <image preserveAspectRatio="xMinYMin slice" xlink:href="@/assets/images/image01.jpg" mask="url(#hero-mask)" ref="image" />
            </svg>
            <div class="title">PEOPLE make <span>products that create</span> stories</div>
            <div class="subtitle">Whenever we produce media and apps for millions of users or viral videos in our own production. It’s always about real people behind it: authors, characters and users.</div>
            <div class="action">
                <a href="">
                    <span>EXPLORE AMO COMPANY</span>
                    <svg>
                        <use xlink:href="#icon-arrow" />
                    </svg>
                </a>
            </div>
        </div>
    </section>
</template>
<script>
import { gsap } from 'gsap';
import { ref, onMounted } from 'vue';

export default {
    name: 'amo-hero',
    setup() {
        const mask = ref(null);
        const svg = ref(null);

        let containerRect = null;
        let vw = 0;
        let windowWidth = 0;
        let containerHeight = 0;

        const animate = (e) => {
            if (mask.value) {
                let x = e ? e.pageX : windowWidth / 2;

                x = Math.max(x, containerRect.x);
                x = Math.min(x, containerRect.width);

                const top = vw * 1.1;
                const coef = (x / 1000) * 200;
                const ray = vw * 3 + coef;

                let right = Math.min(containerRect.width, x - containerRect.x + ray);
                right = Math.max(right, ray * 2);

                let left = Math.max(0, x - containerRect.x - ray);
                left = Math.min(left, containerRect.width - ray * 2);

                gsap.to(mask.value, {
                    duration: 1,
                    attr: {
                        points: top + ' 0, ' + right + ' ' + containerHeight + ', ' + left + ' ' + containerHeight
                    }
                });
            }
        };

        const resize = () => {
            containerRect = svg.value.getBoundingClientRect();
            windowWidth = window.innerWidth;
            vw = windowWidth / 100;
            containerHeight = svg.value.getBoundingClientRect().height;
        };

        onMounted(() => {
            window.addEventListener('resize', resize);
            window.addEventListener('mousemove', animate);

            resize();
            animate();
        });

        return {
            mask,
            svg
        };
    }
};
</script>
<style lang="scss">
.hero {
    overflow: hidden;

    image {
        width: 100%;
        height: 100%;
    }

    svg {
        width: 100%;
        height: size(400);
    }

    .title {
        max-width: size(690);
        font-size: size(50);
        letter-spacing: -0.05em;
        font-weight: bold;
        color: $beige;
        text-transform: uppercase;
        text-align: center;
        margin: size(40) auto 0;
        line-height: 100%;

        span {
            opacity: 0.3;
        }
    }

    .subtitle {
        font-weight: 500;
        font-size: size(25);
        line-height: 100%;
        text-align: center;
        max-width: size(690);
        color: $beige;
        margin: size(40) auto size(40);
    }

    .action {
        margin: 0 auto size(40);
        display: flex;

        a {
            color: $purple;
            text-transform: uppercase;
            font-size: size(20);
            font-weight: 500;
            display: flex;
            align-items: center;
            margin: 0 auto;

            &:hover {
                svg {
                    transform: translate(size(10), size(3));
                }
            }

            svg {
                margin-left: size(4);
                width: size(22);
                height: size(11);
                transform: translate(0, size(3));
                transition: transform $fast;
            }
        }
    }
}
</style>