<template>
    <section class="footer">
        <div class="container">
            <div class="columns">
                <div class="column logo">
                    <div class="wrapper">
                        <span>AMO</span>
                        <svg viewBox="0 0 68 38" fill="none"><path d="M0 38h55.12L67.418 0 0 38Z" fill="#FFF2D8" /></svg>
                    </div>
                </div>
                <div class="column menu">
                    <div class="name">Company</div>
                    <div class="wrapper">
                        <a href="">About</a>
                        <a href="">Culture</a>
                        <a href="">Life</a>
                        <a href="">Press</a>
                    </div>
                </div>
                <div class="column menu">
                    <div class="name">Publishing</div>
                    <div class="wrapper">
                        <a href="">About</a>
                        <a href="">AmoMama</a>
                        <a href="">Social</a>
                        <a href="">Careers</a>
                    </div>
                </div>
                <div class="column menu">
                    <div class="name">Pictures</div>
                    <div class="wrapper">
                        <a href="">About</a>
                        <a href="">Ossa</a>
                        <a href="">Tech</a>
                        <a href="">Careers</a>
                    </div>
                </div>
                <div class="column menu">
                    <div class="name">App</div>
                    <div class="wrapper">
                        <a href="">About</a>
                        <a href="">Products</a>
                        <a href="">Careers</a>
                    </div>
                </div>
                <div class="column menu">
                    <div class="name">Join us</div>
                    <div class="wrapper">
                        <a href="">Vacancies</a>
                    </div>
                </div>
                <div class="column menu">
                    <div class="name">Legal</div>
                    <div class="wrapper">
                        <a href="">Privacy Policy</a>
                        <a href="">Terms and Conditions</a>
                    </div>
                </div>
                <div class="column locales">
                    <a href="" class="is-active">EN</a>
                    <a href="">UA</a>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: 'amo-footer'
};
</script>
<style lang="scss">
.footer {
    color: $beige;
    padding-top: size(17);
    margin-bottom: size(60);

    .name {
        font-size: size(13);
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: size(8);
        margin-top: size(18.5);
    }

    .locales {
        font-size: size(13);
        font-weight: bold;
        text-transform: uppercase;
        flex: none;
        width: unset;

        a + a {
            margin-left: size(12);
        }

        a {
            color: inherit !important;
            opacity: 0.3;
            transition: opacity $fast;
            margin-top: size(18.5);
            display: inline-block;

            &.is-active {
                opacity: 1;
            }

            &:hover {
                opacity: 1;
            }
        }
    }

    .menu {
        .wrapper {
            display: flex;
            flex-flow: column;
        }

        a {
            font-size: size(12);
            font-weight: 500;
            line-height: 110%;
            margin-right: auto;
            color: inherit !important;
            opacity: 0.5;
            transition: opacity $fast;

            &.is-active {
                opacity: 1;
            }

            &:hover {
                opacity: 1;
            }
        }

        a + a {
            margin-top: size(4);
        }
    }

    .logo {
        flex: none;
        width: unset;
        font-size: size(13);
        font-weight: bold;
        text-transform: uppercase;
        color: #000;

        .wrapper {
            position: relative;

            span {
                position: absolute;
                letter-spacing: -0.05em;
                bottom: size(4);
                right: size(14);
                z-index: 1;
            }

            svg {
                width: size(68);
                height: size(38);
            }
        }
    }
}
</style>