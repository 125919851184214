<template>
    <amo-navigation></amo-navigation>
    <amo-hero></amo-hero>
    <amo-image></amo-image>
    <amo-companies></amo-companies>
    <amo-text-centered></amo-text-centered>
    <amo-footer></amo-footer>
    <svg class="is-hidden">
        <symbol id="icon-arrow" viewBox="0 0 24 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M23.03 6.53a.75.75 0 0 0 0-1.06L18.257.697a.75.75 0 0 0-1.06 1.06L21.439 6l-4.242 4.243a.75.75 0 0 0 1.06 1.06L23.03 6.53ZM.5 6.75h22v-1.5H.5v1.5Z" fill="currentColor" /></symbol>
    </svg>
    <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue';
import AmoNavigation from './components/AmoNavigation.vue';
import AmoHero from './components/AmoHero.vue';
import AmoImage from './components/AmoImage.vue';
import AmoCompanies from './components/AmoCompanies.vue';
import AmoTextCentered from './components/AmoTextCentered.vue';
import AmoFooter from './components/AmoFooter.vue';
import { provide } from 'vue';
import { DefaultApolloClient } from '@vue/apollo-composable';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core';

const httpLink = createHttpLink({
    uri: process.env.VUE_APP_STRAPI_API_URL || 'http://localhost:1337'
});

const cache = new InMemoryCache();

const apolloClient = new ApolloClient({
    link: httpLink,
    cache
});

export default {
    name: 'App',
    components: {
        AmoNavigation: AmoNavigation,
        AmoHero: AmoHero,
        AmoImage: AmoImage,
        AmoCompanies: AmoCompanies,
        AmoTextCentered: AmoTextCentered,
        AmoFooter: AmoFooter
        //HelloWorld
    },
    setup() {
        provide(DefaultApolloClient, apolloClient);
    }
};
</script>

<style lang="scss">
html {
    background-color: #000;
    margin-top: size(50);
}

// ::selection {
//     color: #000;
//     background: $beige;
// }
</style>
