<template>
    <section class="text-centered">
        <div class="wrapper">
            <a href="" class="action">
                <span>EXPLORE AMO PUBLISHING</span>
                <svg>
                    <use xlink:href="#icon-arrow" />
                </svg>
            </a>
            <div class="title">We believe that world would be better if everyone knew their superpowers. AMO is helping people find and develop them. Thats our mission.</div>
            <div class="description">Your superpower may exist across many areas at AMO. If you think you’re belong to us – just get in touch. Even if your ideal role is not on the list of our <a href="">open vacancies</a>.</div>
            <a href="" class="amo-button"><span>career at amo</span></a>
        </div>
    </section>
</template>
<script>
export default {
    name: 'amo-text-centered'
};
</script>
<style lang="scss">
.text-centered {
    .wrapper {
        text-align: center;
        background-color: $beige;
        padding: size(150) 0;
    }

    .action {
        text-transform: uppercase;
        font-size: size(20);
        font-weight: 500;
        display: inline-flex;
        align-items: center;
        color: #000;

        &:hover {
            svg {
                transform: translate(size(10), size(3));
            }
        }

        svg {
            margin-left: size(4);
            width: size(22);
            height: size(11);
            transform: translate(0, size(3));
            transition: transform $fast;
        }
    }

    .title {
        font-size: size(50);
        letter-spacing: -0.05em;
        font-weight: bold;
        max-width: size(900);
        margin: size(40) auto;
        line-height: 100%;
    }

    .description {
        font-size: size(20);
        line-height: 100%;
        margin: 0 auto size(40);
        max-width: size(540);

        a {
            color: inherit;
            position: relative;

            &:after {
                content: '';
                position: absolute;
                bottom: -5px;
                border-bottom: size(1) solid #000;
                width: 100%;
                left: 0;
                pointer-events: none;
            }
        }
    }

    .amo-button {
        background-color: $purple;
        border-radius: size(8);
        text-transform: uppercase;
        font-size: size(15);
        color: #000;
        font-weight: 500;
        height: size(50);
        display: inline-flex;
        margin: 0 auto;
        align-items: center;
        justify-content: center;

        span {
            padding: 0 size(38);
            transform: translateY(-1px);
        }
    }
}
</style>