import {
    createApp
} from 'vue'
import App from './App.vue'

//import VueApollo from "./vue-apollo";
//import VueRouter from "vue-router";

const app = createApp(App);

app.mount('#app');
//app.component(VueApollo);
//app.component(VueRouter);