<template>
    <div class="container is-fluid">
        <figure class="image is-rounded">
            <img src="@/assets/images/image01.jpg" />
        </figure>
    </div>
</template>
<script>
export default {
    name: 'amo-image'
};
</script>
<style lang="scss">
.image {
    &.is-rounded {
        img {
            border-radius: size(20);
        }
    }
}
</style>